import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import './SeoFooter.scss'

import headerIcon from '../../../../assets/re-rewamp/Lightning (1).png'







function SeoFooter({heading,content,isReadMoreOrNot}) {


    const characterLimit = 500;
    const [isReadMore, setIsReadMore] = useState(true);


    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <div className='seo-footer'>
            {heading && <div className="seo-footer-title-img">
                <img className='icon-img' src={headerIcon} alt="NueGo popular routes" />
                <h2 className='title'>{heading}</h2>
            </div>}
            <div className="seo-footer-body">
                {(isReadMore && isReadMoreOrNot && content )?
                    <ReactMarkdown>
                        {content?.slice(0, characterLimit) + "..."}
                    </ReactMarkdown>
                    :
                    <ReactMarkdown>
                        {content}
                    </ReactMarkdown>
                }
               { isReadMoreOrNot && <div onClick={toggleReadMore} className='read-toggle'>
                    {isReadMore ? "Read More" : "Read Less"}
                </div>}
            </div>
        </div>
    )
}

export default SeoFooter