import React, {useEffect, useState} from 'react'
import './AuthModalLayout.scss'
import {MDBModal, MDBModalBody} from "mdbreact";
import {useLocation} from "react-router-dom";
// import SignInDesktop from "../../components/AuthComponents/SignInDesktop";
import {setAuthType} from "../../store/Actions/AuthActions";
import {connect} from "react-redux";
import {useMediaQuery} from "react-responsive";
import DesktopAuthComponentLayout from "../../components/AuthComponents/DesktopAuthComponentLayout";
import OtpVerificationMobile from "../../components/AuthComponents/OtpVerificationMobile";
import SignInMobile from "../../components/AuthComponents/SignInMobile";
import TermsConditionsModalContent from "../../components/TermsConditionsModalContent/TermsConditionsModalContent";
import {store} from "../../index";
import {setTermsConditionsModalAction} from "../../store/Actions/CommonAction";
import BottomTrayComponent from "../../components/BottomTrayComponent/BottomTrayComponent";
import FeedbackReminderComponent from "../../components/FeedBackReminderComponent/FeedbackReminderComponent";
import {getPendingReviewData} from "../../services/api/DataFetching";
import TermsConditionsDetail
    from "../../components/TermsConditionsModalContent/TermsConditionsDetail/TermsConditionsDetail";

import closeIcon from "../../assets/icons/cross-gray.svg"
import AuthContextProvider from '../../hooks/context/auth-context';
import cogoToast from "cogo-toast";
import {CustomToast} from "../../components/CustomToast/CustomToast";

const AuthModalLayout = (props) => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const {setTypeToStore, typeFromStore, termsConditionModalFromStore} = props

    const [feedbackReminder, setFeedbackReminder] = useState(false);
    const [pendingReviewData, setPendingReviewData] = useState({});


    const location = useLocation()
    const locationName = location.pathname


    useEffect(() => {
        if (locationName === "/confirmation") {
            setTypeToStore("")
        } else {
            if (typeFromStore !== "") {
                if (isDesktopOrLaptop) {
                    setTypeToStore("d-signin")
                } else {
                    setTypeToStore("m-signin")
                }
            }
        }
    }, []);

    const feedbackRemindersFetch = () => {

        getPendingReviewData(response => {

            setPendingReviewData(response)
            setFeedbackReminder(true)
        }, err => {
            if(err){
                cogoToast.error(
                    <CustomToast
                        heading={"Error"}
                        message={err[0]?.message || err.toString()}
                        type={"error"}
                    />,
                );
            }
            setPendingReviewData({})
            setFeedbackReminder(false)
        })
    }

    const closeTermsConditionModalHandler = () => {
        store.dispatch(setTermsConditionsModalAction(false))
    }

    return (<AuthContextProvider><div className={'auth-modal'}>
        {props.children}

        {/* auth modal*/}
        <MDBModal isOpen={typeFromStore !== ""} position={'center'} centered={true}
                  size={"fluid"}
                  toggle={() => setTypeToStore("")}
                  >
            <MDBModalBody>

                {isDesktopOrLaptop && <DesktopAuthComponentLayout
                    closeModal={() => setTypeToStore("")}
                    feedbackRemindersFetch={feedbackRemindersFetch}
                />}

                {typeFromStore === "m-signin" && <div className={'auth-component'}>
                    <SignInMobile/>
                    </div>}
                {typeFromStore === "m-otp" &&
                    <div className={'auth-component'}>
                        <OtpVerificationMobile feedbackRemindersFetch={feedbackRemindersFetch} />
                    </div>}
                {/*{*/}
                {/*	typeFromStore === "m-otp" && <OtpVerificationMobile closeModal={() => setTypeToStore("")}/>*/}
                {/*}*/}
            </MDBModalBody>
        </MDBModal>

        {/* terms and condition modal */}
        {
            isDesktopOrLaptop ?
                <MDBModal isOpen={termsConditionModalFromStore} position={'center'} centered={true}
                          size={"fluid"}>
                    <MDBModalBody>
                        <TermsConditionsModalContent closeModal={closeTermsConditionModalHandler}/>
                    </MDBModalBody>
                </MDBModal>
                :
                <BottomTrayComponent
                    isOpen={termsConditionModalFromStore}
                    hideClearAll={true}
                    title={"Terms & Conditions"}
                    closeHandler={closeTermsConditionModalHandler}
                >

                    <div className={"terms-condition-details"}>
                        <TermsConditionsDetail/>
                    </div>
                </BottomTrayComponent>


        }


        <div className={'feedback-reminder-modal'}>
            {
                isDesktopOrLaptop ?
                    <MDBModal isOpen={feedbackReminder} position={'center'} centered={true}
                              size={"fluid"}>
                        <MDBModalBody>
                            <div className={'p-4 position-relative'}>
                                <img src={closeIcon} alt={"close"} onClick={() => setFeedbackReminder(false)}
                                     className={'icon-24 close-icon'}
                                />
                                <FeedbackReminderComponent pendingReviewData={pendingReviewData}/>
                            </div>

                        </MDBModalBody>
                    </MDBModal>
                    :
                    <BottomTrayComponent
                        isOpen={feedbackReminder}
                        hideClearAll={true}
                        closeHandler={() => setFeedbackReminder(false)}
                        removeBottomSpacing={true}
                    >
                        <div className={'px-3'}>
                            <FeedbackReminderComponent pendingReviewData={pendingReviewData}/>
                        </div>
                    </BottomTrayComponent>
            }
        </div>

    </div></AuthContextProvider>)
}

const mapStateToProps = state => ({
    typeFromStore: state.AuthReducer.type, mobileNumberFromStore: state.AuthReducer.mobileNumber,
    termsConditionModalFromStore: state.CommonReducer.termsConditionModal

})
const mapDispatchToProps = dispatch => ({
    setTypeToStore: data => dispatch(setAuthType(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AuthModalLayout)
